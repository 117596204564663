import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const AnimatedFooter = () => {
  const footerRef = useRef(null);
  const socialIconsRef = useRef(null);

  useEffect(() => {
    const footer = footerRef.current;
    const socialIconsArray = Array.from(socialIconsRef.current.children);
  
    gsap.set(footer, { y: 50, autoAlpha: 0 });
    gsap.set(socialIconsArray, { scale: 0, autoAlpha: 0 });
  
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: footer,
        start: 'top bottom-=100',
        toggleActions: 'play none none reverse',
      },
    });
  
    tl.to(footer, { duration: 1, y: 0, autoAlpha: 1, ease: 'power3.out' })
      .to(socialIconsArray, {
        duration: 0.5,
        scale: 1,
        autoAlpha: 1,
        stagger: 0.1,
        ease: 'back.out(1.7)',
      });
  
    // Hover animation for social icons
    socialIconsArray.forEach((icon) => {
      icon.addEventListener('mouseenter', () => {
        gsap.to(icon, { duration: 0.3, y: -5, scale: 1.2, ease: 'power2.out' });
      });
      icon.addEventListener('mouseleave', () => {
        gsap.to(icon, { duration: 0.3, y: 0, scale: 1, ease: 'power2.out' });
      });
    });
  
    return () => {
      tl.kill();
      socialIconsArray.forEach((icon) => {
        icon.removeEventListener('mouseenter', () => {});
        icon.removeEventListener('mouseleave', () => {});
      });
    };
  }, []);

  return (
    <div className="bg-gradient-to-r from-[#5AA3ED] to-indigo-800 text-white py-12">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between items-center">
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h3 className="text-2xl font-bold mb-4">opportunities.sciencefair.io</h3>
            <p className="text-sm">
            democratize access to science fair and research resources and tools
            </p>
          </div>
          <div className="w-full md:w-1/3 mb-6 md:mb-0">
            <h4 className="text-lg font-semibold mb-4">Opportunities Quick Links</h4>
            <ul className="space-y-2">
              <li>
                <a href="#" className="hover:text-blue-300">Contests</a>
              </li>
              <li>
                <a href="#" className="hover:text-blue-300">STEM Scholarships</a>
              </li>
              <li>
                <a href="#" className="hover:text-blue-300">Research Opportunities</a>
              </li>
              <li>
                <a href="#" className="hover:text-blue-300">Olympiads</a>
              </li>
            </ul>
          </div>
          <div className="w-full md:w-1/3">
            <a href="https://www.skool.com/qurios/about">
               <h4 className="text-lg font-semibold mb-4">Join our community</h4>
            </a>
            {/* Social icons would go here */}
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-blue-400 text-center">
          <p className="text-sm">
            © {new Date().getFullYear()} ScienceFair.io. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AnimatedFooter;