import React, { useState, useEffect } from 'react';
import db from '../firebase';
import { getDocs, collection, doc, setDoc } from 'firebase/firestore';

function OpportunityForm() {
  const [categories, setCategories] = useState([]);
  const [formData, setFormData] = useState({
    OpportunityName: '',
    Link: '',
    Keywords: '',
    OpportunityExplained: '',
    Field: '',
    Competitiveness: '',
    GradeLevel: '',
    Region: '',
    Deadline: '',
    TipsonProcess: '',
    Timeline: '',
    Fee: '',
    Insights: '',
    ImageLink: '',
    Rank: '',
  });

  useEffect(() => {
    // Fetch categories from Firestore
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "database.sciencefair.io"));
        const categoryList = querySnapshot.docs.map(doc => doc.id);
        setCategories(categoryList);
      } catch (error) {
        console.error("Error fetching categories: ", error);
      }
    };
    fetchCategories();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      const keywordsArray = formData.Keywords.split(',').map(k => k.trim());
      const rankNumber = parseFloat(formData.Rank);

      const opportunityRef = doc(collection(db, "database.sciencefair.io", formData.category, "opportunities"));
      await setDoc(opportunityRef, {
        OpportunityName: formData.OpportunityName,
        OpportunityExplained: formData.OpportunityExplained,
        Keywords: keywordsArray,
        Link: formData.Link,
        Competitiveness: formData.Competitiveness,
        Field: formData.Field,
        GradeLevel: formData.GradeLevel,
        Region: formData.Region,
        Deadline: formData.Deadline,
        TipsonProcess: formData.TipsonProcess,
        Timeline: formData.Timeline,
        Fee: formData.Fee,
        Insights: formData.Insights,
        ImageLink: formData.ImageLink,
        Rank: isNaN(rankNumber) ? null : rankNumber,
      });
      alert('Opportunity added successfully');
      setFormData({
        category: '',
        OpportunityName: '',
        Link: '',
        Keywords: '',
        OpportunityExplained: '',
        Field: '',
        Competitiveness: '',
        GradeLevel: '',
        Region: '',
        Deadline: '',
        TipsonProcess: '',
        Timeline: '',
        Fee: '',
        Insights: '',
        ImageLink: '',
        Rank: '',
      });
    } catch (error) {
      console.error("Error adding document: ", error);
      alert('Error adding opportunity. Please try again.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-semibold text-gray-700 mb-6">Add New Opportunity</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-600 mb-1" htmlFor="category">Category</label>
          <select
            name="category"
            value={formData.category}
            onChange={handleInputChange}
            required
            className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Select Category</option>
            {categories.length > 0 ? (
              categories.map((category) => (
                <option key={category} value={category}>{category}</option>
              ))
            ) : (
              <option value="">No Categories Available</option>
            )}
          </select>
        </div>

        {['OpportunityName', 'Link', 'Keywords', 'OpportunityExplained', 'Field', 'GradeLevel', 'Competitiveness', 'Region', 'Deadline', 'TipsonProcess', 'Timeline', 'Fee', 'Insights', 'ImageLink', 'Rank'].map(field => (
          <div key={field} className="flex flex-col">
            <label className="text-sm font-medium text-gray-600 mb-1" htmlFor={field}>{field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')}</label>
            <input
              name={field}
              value={formData[field]}
              onChange={handleInputChange}
              placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
              required
              className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        ))}

        <button
          type="submit"
          className="w-full py-2 px-4 bg-blue-500 text-white font-semibold rounded-md shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Submit Opportunity
        </button>
      </form>
    </div>
  );
}

export default OpportunityForm;













// import React, { useState } from 'react';
// import db from '../firebase'; // Assuming your Firebase config file is named firebase.js

// function DatabaseAdd() {
//   const [formData, setFormData] = useState({
//     opportunityName: '',
//     link: '',
//     keywords: '',
//     opportunityExplained: '',
//     field: '',
//     competitiveness: '',
//     gradeLevel: '',
//     region: '',
//     deadline: '',
//     timeline: '',
//     fee: '',
//     imageLink: '',
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData((prevData) => ({
//       ...prevData,
//       [name]: value,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await db.collection('database.sciencefair.io').add(formData);
//       alert('Opportunity added successfully');
//       setFormData({
//         opportunityName: '',
//         link: '',
//         keywords: '',
//         opportunityExplained: '',
//         field: '',
//         competitiveness: '',
//         gradeLevel: '',
//         region: '',
//         deadline: '',
//         timeline: '',
//         fee: '',
//         imageLink: '',
//       });
//     } catch (error) {
//       console.error('Error adding opportunity: ', error);
//       alert('Error adding opportunity. Please try again.');
//     }
//   };

//   return (
//     <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-lg">
//       <h2 className="text-2xl font-bold mb-4">Add a New Opportunity</h2>
//       <form onSubmit={handleSubmit} className="space-y-4">
//         <div>
//           <label htmlFor="opportunityName" className="block text-sm font-medium text-gray-700">Opportunity Name</label>
//           <input
//             type="text"
//             name="opportunityName"
//             id="opportunityName"
//             value={formData.opportunityName}
//             onChange={handleChange}
//             placeholder="Opportunity Name"
//             required
//             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//           />
//         </div>
//         <div>
//           <label htmlFor="link" className="block text-sm font-medium text-gray-700">Link</label>
//           <input
//             type="url"
//             name="link"
//             id="link"
//             value={formData.link}
//             onChange={handleChange}
//             placeholder="Link"
//             required
//             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//           />
//         </div>
//         <div>
//           <label htmlFor="keywords" className="block text-sm font-medium text-gray-700">Keywords</label>
//           <input
//             type="text"
//             name="keywords"
//             id="keywords"
//             value={formData.keywords}
//             onChange={handleChange}
//             placeholder="Keywords"
//             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//           />
//         </div>
//         <div>
//           <label htmlFor="opportunityExplained" className="block text-sm font-medium text-gray-700">Opportunity Explained</label>
//           <textarea
//             name="opportunityExplained"
//             id="opportunityExplained"
//             value={formData.opportunityExplained}
//             onChange={handleChange}
//             placeholder="Opportunity Explained"
//             required
//             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//           />
//         </div>
//         <div>
//           <label htmlFor="field" className="block text-sm font-medium text-gray-700">Field</label>
//           <input
//             type="text"
//             name="field"
//             id="field"
//             value={formData.field}
//             onChange={handleChange}
//             placeholder="Field"
//             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//           />
//         </div>
//         <div>
//           <label htmlFor="competitiveness" className="block text-sm font-medium text-gray-700">Competitiveness</label>
//           <input
//             type="text"
//             name="competitiveness"
//             id="competitiveness"
//             value={formData.competitiveness}
//             onChange={handleChange}
//             placeholder="Competitiveness"
//             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//           />
//         </div>
//         <div>
//           <label htmlFor="gradeLevel" className="block text-sm font-medium text-gray-700">Grade Level</label>
//           <input
//             type="text"
//             name="gradeLevel"
//             id="gradeLevel"
//             value={formData.gradeLevel}
//             onChange={handleChange}
//             placeholder="Grade Level"
//             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//           />
//         </div>
//         <div>
//           <label htmlFor="region" className="block text-sm font-medium text-gray-700">Region</label>
//           <input
//             type="text"
//             name="region"
//             id="region"
//             value={formData.region}
//             onChange={handleChange}
//             placeholder="Region"
//             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//           />
//         </div>
//         <div>
//           <label htmlFor="deadline" className="block text-sm font-medium text-gray-700">Deadline</label>
//           <input
//             type="text"
//             name="deadline"
//             id="deadline"
//             value={formData.deadline}
//             onChange={handleChange}
//             placeholder="Deadline"
//             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//           />
//         </div>
//         <div>
//           <label htmlFor="timeline" className="block text-sm font-medium text-gray-700">Timeline</label>
//           <input
//             type="text"
//             name="timeline"
//             id="timeline"
//             value={formData.timeline}
//             onChange={handleChange}
//             placeholder="Timeline"
//             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//           />
//         </div>
//         <div>
//           <label htmlFor="fee" className="block text-sm font-medium text-gray-700">Fee (if any)</label>
//           <input
//             type="text"
//             name="fee"
//             id="fee"
//             value={formData.fee}
//             onChange={handleChange}
//             placeholder="Fee (if any)"
//             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//           />
//         </div>
//         <div>
//           <label htmlFor="imageLink" className="block text-sm font-medium text-gray-700">Image Link</label>
//           <input
//             type="url"
//             name="imageLink"
//             id="imageLink"
//             value={formData.imageLink}
//             onChange={handleChange}
//             placeholder="Image Link"
//             className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
//           />
//         </div>
//         <button
//           type="submit"
//           className="w-full bg-indigo-600 text-white px-4 py-2 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
//         >
//           Submit
//         </button>
//       </form>
//     </div>
//   );
// }

// export default DatabaseAdd;
