import React, { useState,useRef, useEffect } from 'react';
import hfero from "../images/hfero.webp"
import RishabSection from './Rishabsection.js';
import { gsap } from 'gsap';
import AnimatedFooter from "./Footer.js"
import SearchPage from './SearchPage';
import Test from "./text.js"
import Body from "./Body.js"
import image2 from "../images/background.png"
import AOS from "aos";
import "aos/dist/aos.css";

import { useNavigate } from 'react-router-dom';
import Header from '../Header';

import '../App.css'
import RishabList from './RishabList.js';
import Banner from './banner.js';

const links = [
  { name: 'Contests', href: '/search/EssayContest(C2)' },
  { name: 'STEM Scholarships', href: '/search/STEMScholarship(C3)' },
  { name: 'Research Opportunities', href: '/search/ResearchOpportunities(C4)' },
  { name: 'Olympiads', href: '/search/Olympiads(C5)' },
  { name: 'Science Fair', href: '/search/ScienceFair(C1)' },
]

const imageUrls = [
  'https://randomuser.me/api/portraits/thumb/men/2.jpg',
  'https://randomuser.me/api/portraits/thumb/men/4.jpg',
  'https://i.imgur.com/4ux9DOV.png',
  'https://randomuser.me/api/portraits/thumb/men/1.jpg',
  'https://randomuser.me/api/portraits/thumb/men/5.jpg',
];

function HomePage() {

  document.title = "ScienceFair.io — Database";

  const navigate = useNavigate();

  const animationStyle = `
  @keyframes moveBackground {
    0% {
      background-position: 50% 0%;
    }
    25% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 50% 100%;
    }
    75% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 50% 0%;
    }
`;

  const testImages = {
    1: "https://assets.skool.com/f/58a8f168c2114a41a2db0e96df7c4fd7/2986551ce53848e89a29a3fe343d2aa7c563ab24184f47169fcf77bebac768e9-md.jpg",
    2: "https://assets.skool.com/f/7272fe771a9a489d9a2f6aa1a4628b29/41f851662b1e491d8c02b9de3a9190dd15c8f05c41224691a54efacc16598fdd-md.jpg",
    3: "https://assets.skool.com/f/b3bf3c07f25c4e0fb8ba958a1b3fad2b/977b82746999408288d8ec4efbed9336523f7e0b7a1a4cbe987b4b74b152214b",
    4: "https://assets.skool.com/f/e5707ae0556748189ff3c7a503123ae3/b24d3c364c5f40d682156d6a967f14150f6fa14f10b44552bcd631112aa99bc8",
    5: "https://assets.skool.com/f/9f906d4353b44a6b858dbf17179cb3f1/f17bd529748e49b78f1fadc43bef3a60a060c55ca7024b2fa15b23a54a9523ac"
    
  }

  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const linkRefs = useRef([]);

  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: "ease-out-cubic",
    });
    linkRefs.current.forEach((link) => {
      gsap.to(link.querySelector('.arrow'), {
        x: 0,
        opacity: 0,
        duration: 0.3,
        ease: 'power2.out',
      });

      link.addEventListener('mouseenter', () => {
        gsap.to(link.querySelector('.arrow'), {
          x: 5,
          opacity: 1,
          duration: 0.3,
          ease: 'power2.out',
        });
      });

      link.addEventListener('mouseleave', () => {
        gsap.to(link.querySelector('.arrow'), {
          x: 0,
          opacity: 0,
          duration: 0.3,
          ease: 'power2.out',
        });
      });
    });
  }, []);

  const handleInputClick = () => {
    setTimeout(() => {
      navigate('/search');
    }, 100); // Adjust this timing to match your animation duration
  };

  return (
    <div className="h-screen  flex flex-col">

      {!isSearchActive && (
        <div>
          <div className="pb-16">
            <Header />
          </div>


          <div
      aria-hidden="true"
      className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-[200px] sm:-top-80 pointer-events-none"
    >
      <div
        style={{
          clipPath:
            'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
        }}
        className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[72.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-br from-[#5AA3ED] via-[#5AA3ED] to-[#0000FF] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[144.1875rem]"
      />
    </div>
        <style>
            {animationStyle}
          </style>


          <div 
            className="flex-grow flex flex-col sm:py-16 justify-center py-[100px] items-center px-4"
            data-aos="fade-up"
            data-aos-duration="600"
          >
            <br/><br/>
            <h1 className="text-4xl font-serif sm:text-5xl md:text-[75px] font-normal mb-5">Find <span className='italic'>Great</span> <span className="font-bold" style={{color: 'transparent', backgroundImage: `url(${image2})`,  WebkitBackgroundClip: 'text', animation: 'moveBackground 10s ease-in-out infinite', backgroundSize: '200% 200%'}}>STEM</span> Competitions.</h1>

            <p className="text-2xl font-serif sm:text-xl md:text-2xl leading-8 text-gray-600 mb-10">We provide a gateway to curated, high-quality STEM competitions and scholarships.</p>

            
            
          
            
            <div className="w-full sm:w-2/3 md:w-1/2 lg:w-1/3 mb-6">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search by interest, name..."
                  className="w-full p-4 pr-12 rounded-full border-2 border-gray-300 focus:outline-none focus:border-blue-500 cursor-pointer"
                  onClick={handleInputClick}
                  readOnly
                />
                <button onClick={handleInputClick} className="absolute right-4 top-1/2 transform -translate-y-1/2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                  </svg>
                </button>
              </div>
              <div className="flex items-center justify-center mt-4">
                <div className="flex -space-x-2 overflow-hidden">
                  {[1, 2, 3, 4, 5].map((index) => (
                    <img
                      key={index}
                      className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                      src={testImages[index]}
                      alt=""
                    />
                  ))}
                </div>
                <p className="ml-3 text-xs sm:text-sm text-gray-600">Join +40,000 other students</p>
              </div>
            </div>
            <div className="mt-8 sm:mt-16 grid grid-cols-2 gap-4 sm:gap-x-8 sm:gap-y-6 text-sm sm:text-base font-light leading-7 text-gray-600 md:flex lg:gap-x-10">
              {links.map((link, index) => (
                <a
                  key={link.name}
                  href={link.href}
                  ref={(el) => (linkRefs.current[index] = el)}
                  className="relative group text-center transition-colors duration-300 hover:text-blue-500"
                >
                  <span className="relative z-10">{link.name}</span>
                  <span className="arrow absolute right-[-10px] top-1/2 transform -translate-y-1/2 opacity-0" aria-hidden="true">
                    &rarr;
                  </span>
                  <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-500 transform origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100"></span>
                </a>
              ))}

            </div>
            
          </div>

          <div className="hidden md:flex justify-center w-full">
            <img className="w-2/3 lg:w-1/2" src={hfero} alt="Hero image" />
          </div>

          {/* <Banner/> */}

          {/* <div className="hidden md:flex justify-center w-full px-4 mb-8">
            <img className="w-2/3 lg:w-1/2" src={hfero} alt="Hero image" />
          </div> */}

          <div className="bg-white w-full">
            <div className="p-4 sm:p-8 md:p-16 flex flex-col items-center">
              <Body/>
              <br/>
              <RishabSection/>
              {/* <RishabList/> */}
            </div>
          </div>

          
          

          <div>
          


            <div className="bg-gradient-to-r from-[#5AA3ED] to-indigo-800 text-white py-12">
              <div className="container mx-auto px-4">
                <div className="flex flex-wrap justify-between items-center">
                  <div className="w-full md:w-1/3 mb-6 md:mb-0">
                    <h3 className="text-2xl font-bold mb-4">opportunities.sciencefair.io</h3>
                    <p className="text-sm">
                    democratize access to science fair <br/> and research resources and tools
                    </p>
                  </div>
                  <div className="w-full md:w-1/3 mb-6 md:mb-0">
                    <h4 className="text-lg font-semibold mb-4">Opportunities Quick Links</h4>
                    <ul className="space-y-2">
                      <li>
                        <a href="/search/EssayContest(C2)" className="hover:text-blue-300">Contests</a>
                      </li>
                      <li>
                        <a href="/search/STEMScholarship(C3)" className="hover:text-blue-300">STEM Scholarships</a>
                      </li>
                      <li>
                        <a href="/search/ResearchOpportunities(C4)" className="hover:text-blue-300">Research Opportunities</a>
                      </li>
                      <li>
                        <a href="/search/Olympiads(C5)" className="hover:text-blue-300">Olympiads</a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full md:w-1/3">
                    <a href="https://www.skool.com/qurios/about">
                       <h4 className="text-lg font-semibold mb-4">Join our community</h4>
                    </a>
                    {/* Social icons would go here */}
                  </div>
                </div>
                <div className="mt-8 pt-8 border-t border-blue-400 text-center">
                  <p className="text-sm">
                    © {new Date().getFullYear()} ScienceFair.io. All rights reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
       
        </div>
      )}
        
    </div>
  );
}

export default HomePage;
