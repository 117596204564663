import React, { useState, useEffect } from 'react';
import gsap from 'gsap'; // Make sure this line is present
import AnimatedSection from "./GsapAni"
import SearchPage from './SearchPage';
import image4 from "../images/db-sci.webp"
import image2 from "../images/background.png"
import AOS from "aos";
import "aos/dist/aos.css";

import Header from '../Header';

function Body() {

  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: "ease-out-cubic",
    });
  }, []);

  useEffect(() => {
    // Fade-in animation for the container
    gsap.from(".container", {
      opacity: 0,
      y: 50,
      duration: 1.5,
      ease: "power3.out",
    });

    // Animate the background of the text
    gsap.to(".animated-text", {
      backgroundPosition: "200% 200%",
      duration: 10,
      ease: "power1.inOut",
      repeat: -1,
      yoyo: true,
    });
  }, []);


  


  return (

    // <div className="container mx-auto py-16 px-4">
    //     <div className="grid grid-cols-2 gap-4">
    //         <div class=" justify-center ...">
    //           <h1 className="text-[55px] font-extrabold mb-5">Exclusively <span style={{color: 'transparent', backgroundImage: `url(${image2})`,  WebkitBackgroundClip: 'text', animation: 'moveBackground 10s ease-in-out infinite', backgroundSize: '200% 200%'}}>Curated</span> for STEM Excellence</h1>
    //           <p className="text-xl leading-8 text-gray-900 mb-10">
    //           At our core, we believe in quality over quantity. Unlike other platforms, we meticulously vet each STEM competition and scholarship to ensure they meet the highest standards of educational and professional excellence. Our commitment is to provide students and educators with access to only the most prestigious and impactful opportunities in STEM.
    //                 </p>

    //         </div>
    //         <div class="flex pt-9 px-4 flex items-stretch">
    //             <img className="scale-75 self-center" src={image4}></img>

    //         </div>
    //     </div>
    // </div>

    <AnimatedSection image2={image2} image4={image4} />

    // <div className="container mx-auto py-16 px-4">
      
    //   <div className="grid grid-cols-2 gap-4">
    //     <div className="flex justify-center flex-col">
    //       <h1 className="text-[55px] font-extrabold mb-5">
    //         Exclusively{" "}
    //         <span
    //           className="animated-text"
    //           style={{
    //             color: "transparent",
    //             backgroundImage: `url(${image2})`,
    //             WebkitBackgroundClip: "text",
    //             backgroundClip: "text",
    //             backgroundSize: "200% 200%",
    //           }}
    //         >
    //           Curated
    //         </span>{" "}
    //         for STEM Excellence
    //       </h1>
    //       <p className="text-xl leading-8 text-gray-900 mb-10 hover:underline">
    //         At our core, we believe in quality over quantity. Unlike other
    //         platforms, we meticulously vet each STEM competition and scholarship
    //         to ensure they meet the highest standards of educational and
    //         professional excellence. Our commitment is to provide students and
    //         educators with access to only the most prestigious and impactful
    //         opportunities in STEM.
    //       </p>
    //     </div>
    //     <div className="flex justify-center items-center">
    //       <img
    //         className="scale-75 mb-6 hover:scale-85 transform transition-transform duration-300 ease-in-out"
    //         src={image4}
    //         alt="STEM Excellence"
    //       />
    //     </div>
    //   </div>
    // </div>


   
    
   
  );
}

export default Body;
