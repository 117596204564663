import React, { useState, useEffect } from 'react';
import placeholder_image from "../images/background.png"
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import db from "../firebase";
import Header from '../Header';

function ExpandedPage() {
  const { categoryId, oppId } = useParams();
  const [opportunity, setOpportunity] = useState(null);

  useEffect(() => {
    const fetchOpportunity = async () => {
      const oppRef = doc(db, 'database.sciencefair.io', categoryId, 'opportunities', oppId);
      const oppSnap = await getDoc(oppRef);

      if (oppSnap.exists()) {
        setOpportunity({ id: oppSnap.id, ...oppSnap.data() });
      } else {
        console.log("No such opportunity!");
      }
    };

    fetchOpportunity();
  }, [categoryId, oppId]);

  if (!opportunity) return <div className="text-center p-4 text-gray-600">Loading...</div>;

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      <Header />
      <div className="container mx-auto p-8 flex-grow mt-20">
        <div className="bg-white shadow-2xl rounded-lg overflow-hidden">
          <img
            src={opportunity.ImageLink || placeholder_image}
            alt={opportunity.OpportunityName}
            className="w-full h-72 object-cover rounded-t-lg"
          />
          <div className="p-8">
            <h1 className="text-5xl font-extrabold text-gray-900 mb-6 flex items-center justify-between">
            <span>{opportunity.OpportunityName}</span>
            <span className="text-xl font-semibold text-gray-900">
                1
            </span>
            </h1>

            <p className="text-lg text-gray-700 mb-6">{opportunity.OpportunityExplained}</p>
            <div className="text-gray-600 text-base space-y-3">
              <p><span className="font-semibold text-gray-800">Deadline:</span> {opportunity.Deadline}</p>
              <p><span className="font-semibold text-gray-800">Competitiveness:</span> {opportunity.Competitiveness}</p>
              <p><span className="font-semibold text-gray-800">Fee:</span> {opportunity.Fee}</p>
              <p><span className="font-semibold text-gray-800">Grade Level:</span> {opportunity.GradeLevel}</p>
              <p><span className="font-semibold text-gray-800">Link:</span> <a href={opportunity.Link} className="text-blue-600 hover:underline">{opportunity.Link}</a></p>
              <p><span className="font-semibold text-gray-800">Timeline:</span> {opportunity.Timeline}</p>
              <p><span className="font-semibold text-gray-800">Region:</span> {opportunity.Region}</p>
              <p><span className="font-semibold text-gray-800">Tips on Process:</span> {opportunity.TipsonProcess}</p>
            </div>

            <div className="mt-6 flex flex-wrap gap-2">
              {opportunity.Keywords.map((keyword, index) => (
                <span key={index} className="bg-blue-500 text-white px-4 py-1 rounded-full text-sm font-medium">
                  {keyword}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpandedPage;


