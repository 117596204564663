import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

function RishabList() {
  const listRef = useRef(null);
  const itemsRef = useRef([]);

  const opportunities = [
    "opportunity 1",
    "etc. Do you like this idea?",
    "opportunity 3",
    "Biomedical Engineering",
    "Data Science and Analytics",
    "Robotics and Automation",
    "Cybersecurity",
    "Nanotechnology",
    "Space Exploration",
    "Genetic Engineering",
    "3D Printing and Additive Manufacturing",
    "Virtual and Augmented Reality",
    "Blockchain Technology",
    "Environmental Science and Sustainability",
    "Neuroscience",
    "Machine Learning",
    "Internet of Things (IoT)",
    "Bioinformatics",
    "Clean Water Technology",
    "Advanced Materials Science"
  ];

  useEffect(() => {
    const list = listRef.current;
    const items = itemsRef.current;

    // Initial fade-in animation for the entire list
    gsap.fromTo(list, 
      { opacity: 0, y: 100 },
      { opacity: 1, y: 0, duration: 1, ease: "power3.out" }
    );

    // Fade-in animation for each item
    items.forEach((item, index) => {
      gsap.fromTo(item,
        { opacity: 0, x: index % 2 === 0 ? -50 : 50 },
        {
          opacity: 1,
          x: 0,
          duration: 0.8,
          delay: 0.1 * index,
          ease: "power3.out",
          scrollTrigger: {
            trigger: item,
            start: "top bottom-=100",
            toggleActions: "play none none none"
          }
        }
      );
    });

    // Hover animations
    items.forEach((item) => {
      const number = item.querySelector('.number');
      const text = item.querySelector('.text');

      gsap.set([number, text], { transformOrigin: "left center" });

      item.addEventListener('mouseenter', () => {
        gsap.to(item, { scale: 1.05, duration: 0.3, ease: "power2.out" });
        gsap.to(number, { scale: 1.2, color: "#3B82F6", duration: 0.3 });
        gsap.to(text, { x: 10, duration: 0.3 });
      });

      item.addEventListener('mouseleave', () => {
        gsap.to(item, { scale: 1, duration: 0.3, ease: "power2.out" });
        gsap.to(number, { scale: 1, color: "#1E40AF", duration: 0.3 });
        gsap.to(text, { x: 0, duration: 0.3 });
      });
    });

    // Clean up function
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <div className="container mx-auto px-4 py-16 max-w-5xl" ref={listRef}>
      <h1 className="text-5xl font-bold text-center mb-8 text-blue-900">Overall Top 20 STEM Opportunities</h1>
      <p className="text-center text-xl mb-12 text-blue-700">As chosen by the ScienceFair.io team</p>
      <ol className="space-y-6">
        {opportunities.map((opportunity, index) => (
          <li 
            key={index}
            ref={el => itemsRef.current[index] = el}
            className="bg-gradient-to-r from-blue-100 to-indigo-100 rounded-lg shadow-md transition-all duration-300 hover:shadow-xl"
          >
            <div className="p-6 flex items-center">
              <span className="number text-4xl font-bold text-blue-800 mr-6 w-12 text-right">{index + 1}.</span>
              <p className="text text-xl font-medium text-gray-800">{opportunity}</p>
            </div>
          </li>
        ))}
      </ol>
    </div>
  );
}

export default RishabList;