import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const RishabSection = () => {
  const sectionRef = useRef(null);
  const iframeRef2 = useRef(null);
  const titleRef = useRef(null);
  const iframeRef = useRef(null);
  const buttonRef = useRef(null);
  

  useEffect(() => {
    const section = sectionRef.current;
    const iframe2 = iframeRef2.current;
    const title = titleRef.current;
    const iframe = iframeRef.current;
    const button = buttonRef.current;

    gsap.set([title, iframe, iframe2, button], { autoAlpha: 0, y: 50 });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: 'top 80%',
        end: 'bottom 20%',
        toggleActions: 'play none none reverse',
      },
    });

    tl.to(title, { duration: 1, autoAlpha: 1, y: 0, ease: 'power3.out' })
      .to([iframe, iframe2], { duration: 1, autoAlpha: 1, y: 0, ease: 'power3.out' }, '-=0.5')
      .to(button, { duration: 1, autoAlpha: 1, y: 0, ease: 'power3.out' }, '-=0.5');

    // Animated underline for Rishab's name
    gsap.to('.animated-underline', {
      backgroundSize: '100% 3px',
      duration: 1,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: '.animated-underline',
        start: 'top 80%',
        toggleActions: 'play none none reverse',
      },
    });

    return () => {
      tl.kill();
    };
  }, []);

  return (
    <div ref={sectionRef} className="py-16 flex flex-col items-center">
      <h2 ref={titleRef} className="text-4xl font-serif md:text-5xl font-bold mb-8 text-center">
        Learn about{' '}
        <span className="relative inline-block">
          <a
            href="https://www.rishabjaink.com/"
            className="animated-underline transition-colors duration-300"
            style={{
              backgroundImage: 'linear-gradient(90deg, #3B82F6, #2563EB)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '0 100%',
              backgroundSize: '0% 3px',
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Rishab's
          </a>
        </span>{' '}
        Personal Tier-List
      </h2>

      <div className="flex flex-col md:flex-row w-full space-y-4 md:space-y-0 md:space-x-4 p-8">
        <div ref={iframeRef} className="w-full md:w-1/2" style={{ height: '55vh' }}>
          <iframe
            src="https://www.youtube.com/embed/32Uq1qNSDz4?si=H8hoVwqn-2MzQhjR?start=1"
            className="w-full h-full rounded-2xl shadow-2xl transform transition-transform duration-300 hover:scale-105"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube video player 1"
          ></iframe>
        </div>
        <div ref={iframeRef2} className="w-full md:w-1/2" style={{ height: '55vh' }}>
          <iframe
            src="https://www.youtube.com/embed/h7OlgFLByK8?si=PsA7j3u66PGV4hvM?start=1" // Leave this blank for now
            className="w-full h-full rounded-2xl shadow-2xl transform transition-transform duration-300 hover:scale-105"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube video player 2"
          ></iframe>
        </div>
      </div>

      <a href="https://rishabacademy.com/lists/" target="_blank" rel="noopener noreferrer">
        <button
          ref={buttonRef}
          className="See-Rishab-full-list-coolbutton font-bold shadow-lg transform transition-all duration-300 ease-in-out hover:scale-110 hover:shadow-2xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
        >
          {/* <span className="flex items-center">
            <span>See Rishab's Full List Here</span>
            <svg
              className="w-5 h-5 ml-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </span> */}

          
          {/* <a href="/Rishab-list" target="_blank">
            <button className="See-Rishab-full-list-coolbutton"> */}
              <span>See Rishab's Full List Here</span>
            {/* </button>
          </a> */}
        </button>
      </a>
    </div>
  );
};

export default RishabSection;
