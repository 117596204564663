import React, { useState, useRef, useEffect } from "react";
import { ChevronDownIcon, Menu, X } from 'lucide-react';
import { useNavigate } from "react-router-dom";
import Image from './images/logo.svg';
import gsap from "gsap";
import Banner from "./components/banner";

const FlipNavWrapper = ({ trigger, user }) => {
  return (
    <div className="fixed z-50 top-0 left-0 w-full">
      <div className="relative z-50">
        <FlipNav trigger={trigger} user={user} />
      </div>
      <div className="relative z-40">
        <Banner/>
      </div>
    </div>
  );
};

const FlipNav = ({ trigger, user }) => {
  const [isToolsOpen, setIsToolsOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const toolsMenuRef = useRef(null);
  const mobileMenuRef = useRef(null);

  useEffect(() => {
    if (isToolsOpen) {
      gsap.fromTo(toolsMenuRef.current, 
        { opacity: 0, y: -10 },
        { opacity: 1, y: 0, duration: 0.3, ease: "power2.out" }
      );
    }
  }, [isToolsOpen]);

  useEffect(() => {
    if (isMobileMenuOpen) {
      gsap.fromTo(mobileMenuRef.current,
        { opacity: 0, x: -20 },
        { opacity: 1, x: 0, duration: 0.3, ease: "power2.out" }
      );
    }
  }, [isMobileMenuOpen]);

  const toggleToolsMenu = () => {
    setIsToolsOpen(!isToolsOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLaunchApp = () => {
    window.location.href = 'https://app.sciencefair.io/';
  };

  const openApp = () => {
    window.location.href = 'https://app.sciencefair.io/';
    
  }

  return (
    <nav className="p-4 bg-white/60   backdrop-blur-lg">
      <div className="max-w-7xl mx-auto flex items-center justify-between">
        <div className="flex items-center">
          <a href="https://www.sciencefair.io/" aria-label="Home" className="transition-transform duration-300 hover:scale-105">
            <img
              src={Image}
              height={65}
              width={208}
              alt="logo"
              draggable={false}
              className="h-12 w-auto"
            />
          </a>
        </div>
        
        {/* Desktop Menu */}
        <div className="hidden md:flex font-bold items-center space-x-6">
        <NavLink href="https://www.sciencefair.io/guide">Guide</NavLink>
        <NavLink href="https://www.sciencefair.io/coaching">Coaching</NavLink>
        <NavLink href="/">Opportunities</NavLink>
          
          <div className="relative">
            <button 
              onClick={toggleToolsMenu} 
              className="flex items-center space-x-1 px-3 py-2 rounded hover:bg-gray-200 transition-colors duration-300"
            >
              <span>Tools</span>
              <ChevronDownIcon className={`h-4 w-4 transition-transform duration-300 ${isToolsOpen ? 'rotate-180' : ''}`} />
            </button>
            
            {isToolsOpen && (
              <div 
                ref={toolsMenuRef} 
                className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 opacity-0"
              >
                <DropdownLink href="https://www.sciencefair.io/idea-discovery">Idea Discovery</DropdownLink>
              <DropdownLink href="https://www.sciencefair.io/tuners">Project Tuners</DropdownLink>
              <DropdownLink href="https://www.sciencefair.io/findafair">Find a Fair</DropdownLink>
              <DropdownLink href="https://www.sciencefair.io/judge">Mock Judging</DropdownLink>
              </div>
            )}
          </div>
          
          <button 
            onClick={handleLaunchApp}
            className="text-white bg-[#6CA1E7] px-4 py-2 rounded transition-all duration-300 hover:bg-[#5A8DD3] hover:shadow-md"
          >
            Launch App
          </button>
        </div>

        {/* Mobile Menu Button */}
        <div className="md:hidden">
          <button onClick={toggleMobileMenu} className="text-gray-500 hover:text-gray-600">
            {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div ref={mobileMenuRef} className="md:hidden mt-4 opacity-0">
          <NavLink href="https://www.sciencefair.io/guide" mobile>Guide</NavLink>
            <NavLink href="https://www.sciencefair.io/coaching" mobile>Coaching</NavLink>
            <NavLink href="/" mobile>Opportunities</NavLink>
          <div className="mt-2">
            <button 
              onClick={toggleToolsMenu}
              className="w-full text-left px-4 py-2 font-bold hover:bg-gray-100"
            >
              Tools
              <ChevronDownIcon className={`h-4 w-4 inline ml-2 transition-transform duration-300 ${isToolsOpen ? 'rotate-180' : ''}`} />
            </button>
            {isToolsOpen && (
              <div className="pl-8">
                <DropdownLink href="https://www.sciencefair.io/idea-discovery">Idea Discovery</DropdownLink>
              <DropdownLink href="https://www.sciencefair.io/tuners">Project Tuners</DropdownLink>
              <DropdownLink href="https://www.sciencefair.io/findafair">Find a Fair</DropdownLink>
              <DropdownLink href="https://www.sciencefair.io/judge">Mock Judging</DropdownLink>
              </div>
            )}
          </div>
          <button 
            onClick={openApp}
            className="w-full mt-4 text-white bg-[#6CA1E7] px-4 py-2 rounded transition-all duration-300 hover:bg-[#5A8DD3]"
          >
            Launch App
          </button>
        </div>
      )}
    </nav>
  );
};

const NavLink = ({ href, children, mobile }) => (
  <a 
    href={href} 
    className={`relative overflow-hidden group ${mobile ? 'block w-full px-4 py-2 hover:bg-gray-100' : ''}`}
  >
    <span className={`relative z-10 transition-colors duration-300 ${mobile ? '' : 'group-hover:text-blue-500'}`}>
      {children}
    </span>
    {!mobile && (
      <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-500 transform origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100"></span>
    )}
  </a>
);

const DropdownLink = ({ href, children, mobile }) => (
  <a 
    href={href} 
    className={`block px-4 py-2 hover:bg-gray-200 transition-colors duration-200 ${mobile ? 'text-sm' : ''}`}
  >
    {children}
  </a>
);

export default FlipNavWrapper;






// import React from 'react';
// import Image from './images/logo.svg';

// const Header = () => {
//   return (
//     <nav className="bg-gradient-to-r from-white via-white to-blue-50 shadow-sm flex justify-between items-center p-5">
//       <div className="flex items-center">
//         <img src={Image} alt="ScienceFair.io" style={{height: '40px'}} className="mr-2" />
//       </div>
//       <div className="flex space-x-8 text-gray-600">
//         <a href="#" className="hover:text-black">Ideation</a>
//         <a href="#" className="hover:text-black">Guide</a>
//         <a href="#" className="hover:text-black">Compete</a>
//         <a href="#" className="hover:text-black">Judging</a>
//         <a href="#" className="hover:text-black">Coaching</a>
//       </div>
//       <div>
//         <button className="bg-blue-500 text-white px-4 py-2 rounded-md">Log In</button>
//       </div>
//     </nav>
//   );
// };

// export default Header;





// import Image from './images/logo.svg';
// import React, { useState, useEffect } from 'react';
// import SearchPage from './components/SearchPage';
// import AOS from "aos";
// import "aos/dist/aos.css";
// import { Link } from 'react-router-dom';

// function Header() {
//     const [isSearchActive, setIsSearchActive] = useState(false);

//     useEffect(() => {
//         AOS.init({
//             duration: 500,
//             easing: "ease-out-cubic",
//         });
//     }, []);

//     const handleInputClick = () => {
//         setIsSearchActive(true);
//     };

//     return (
//         <div>
//             {isSearchActive && (
//                 <div 
//                     className="fixed inset-0 bg-white z-50 pointer-events-none"
//                     data-aos="zoom-in-up"
//                 >
//                     <SearchPage onClose={() => setIsSearchActive(false)} />
//                 </div>
//             )}
            
//             <div data-aos="fade-down">
//                 <nav className="absolute top-7 left-1/2 transform -translate-x-1/2 flex justify-between items-center w-11/12 max-w-5xl px-6 bg-white rounded-full shadow-md">
//                     <div className="">
//                         <a href="/">
//                           <div className="flex items-center">
//                             <img src={Image} style={{ width: '180px', height: '75px' }} alt="Logo" />
//                           </div>
//                        </a>
//                     </div>
//                     <div className="flex items-center">
//                         <a href="/" className="text-gray-800 font-medium mx-1">Home</a>
//                         <div className="h-5 w-px bg-gray-500 mx-2 mt-1"></div>
//                         <button className="ml-1" onClick={handleInputClick}>
//                             <span className="text-gray-800 font-medium mx-1">Search</span>
//                         </button>
//                     </div>
//                 </nav>
//             </div>

//         </div>
//     )
// }

// export default Header;
