import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const options = [
  { name: 'Top 20 Contests', href: '/search/EssayContest(C2)' },
  { name: 'Top 20 STEM Scholarships', href: '/search/STEMScholarship(C3)' },
  { name: 'Top 20 Research Opportunities', href: '/search/ResearchOpportunities(C4)' },
  { name: 'Top 20 Olympiads', href: '/search/Olympiads(C5)' },
];

const AnimatedHook = () => {
  const optionsRef = useRef([]);
  const containerRef = useRef(null);

  useEffect(() => {
    // Ensure GSAP is available
    if (typeof gsap !== 'undefined') {
      // Set initial state
      gsap.set(optionsRef.current, { opacity: 0, y: 50 });

      // Create the animation
      gsap.to(optionsRef.current, {
        opacity: 1,
        y: 0,
        stagger: 0.2,
        duration: 1,
        ease: "power3.out",
        scrollTrigger: {
          trigger: containerRef.current,
          start: "top center+=100",
        },
      });
    }
  }, []);

  const handleHover = (index, isEnter) => {
    gsap.to(optionsRef.current[index], {
      scale: isEnter ? 1.1 : 1,
      boxShadow: isEnter ? "0 10px 20px rgba(0,0,0,0.2)" : "0 4px 6px rgba(0,0,0,0.1)",
      y: isEnter ? -5 : 0,
      duration: 0.3
    });
  };

  return (
    <div ref={containerRef} className="flex flex-col bg-[radial-gradient(circle_at_center,_rgba(90,163,237,0.3)_0%,_rgba(90,163,237,0.1)_30%,_rgba(90,163,237,0)_70%)] items-center justify-center min-h-screen p-4">
      <h1 className="text-5xl font-serif font-bold text-black mb-10">Choose an Option</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 w-full max-w-4xl">
        {options.map((option, index) => (
          <a
            key={option.name}
            href={option.href}
            ref={el => optionsRef.current[index] = el}
            className="bg-white rounded-lg p-6 text-center transition-all duration-300 transform hover:rotate-3 opacity-0"
            onMouseEnter={() => handleHover(index, true)}
            onMouseLeave={() => handleHover(index, false)}
          >
            <h2 className="text-2xl font-semibold text-[#5AA3ED]">{option.name}</h2>
            <p className="mt-2 text-gray-600">Click to explore</p>
          </a>
        ))}
      </div>
    </div>
  );
};

export default AnimatedHook;
