import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, onSnapshot, doc, orderBy } from 'firebase/firestore'; // Added orderBy here
import db from "../firebase";
import placeholder_image from "../images/background.png"
import { Link } from 'react-router-dom';
import AOS from "aos";
import "aos/dist/aos.css";
import Header from '../Header';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import logo from '../images/logo2.webp'
import '../App.css'

function SearchPage() {

  const { category } = useParams();
  const location = useLocation();

  document.title = "Search | ScienceFair.io — Database";
  const [selectedOption, setSelectedOption] = useState('All');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCategoryName, setSelectedCategoryName] = useState('');
  const [opportunities, setOpportunities] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [allOpportunities, setAllOpportunities] = useState([]);
  const [categoryOpportunities, setCategoryOpportunities] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'database.sciencefair.io'), (snapshot) => {
      const categoryList = snapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name
      }));
      setCategories(categoryList);

      // Set the selected category based on the URL parameter
      if (category) {
        const selectedCat = categoryList.find(cat => cat.id.toLowerCase() === category.toLowerCase());
        if (selectedCat) {
          setSelectedCategory(selectedCat.id);
          setSelectedCategoryName(selectedCat.name);
        } else {
          // If category doesn't exist, redirect to search page
          navigate('/search');
        }
      } else {
        // Reset selected category when on the search page
        setSelectedCategory('');
        setSelectedCategoryName('');
      }
    });

    return () => unsubscribe();
  }, [category, navigate]);


  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'database.sciencefair.io'), (snapshot) => {
      const categoryList = snapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name
      }));
      setCategories(categoryList);
    });

    return () => unsubscribe();
  }, []);

useEffect(() => {
  const fetchAllOpportunities = async () => {
    const allOpps = [];
    for (const category of categories) {
      const opportunitiesRef = collection(db, 'database.sciencefair.io', category.id, 'opportunities');
      const q = query(opportunitiesRef, orderBy('OpportunityName'));
      const snapshot = await getDocs(q);
      snapshot.docs.forEach(doc => {
        allOpps.push({
          id: doc.id,
          category: category.name,
          ...doc.data()
        });
      });
    }
    setAllOpportunities(allOpps);
  };

  if (categories.length > 0) {
    fetchAllOpportunities();
  }
}, [categories]);

useEffect(() => {
  if (selectedCategory) {
    const opportunitiesRef = collection(db, 'database.sciencefair.io', selectedCategory, 'opportunities');
    const q = query(opportunitiesRef, orderBy('OpportunityName'));
    
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const opportunityList = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCategoryOpportunities(opportunityList);
    });

    return () => unsubscribe();
  } else {
    setCategoryOpportunities([]);
  }
}, [selectedCategory]);


  const updateSearchSuggestions = (term) => {
    if (term.length === 0) {
      setSearchSuggestions([]);
      return;
    }
    
    const suggestions = allOpportunities.filter(opp =>
      opp.OpportunityName.toLowerCase().includes(term.toLowerCase())
    ).slice(0, 5); // Limit to 5 suggestions
  
    setSearchSuggestions(suggestions);
  };

  const handleSuggestionClick = (suggestion) => {
    const category = categories.find(cat => cat.name === suggestion.category);
    setSelectedCategory(category.id);
    setSelectedCategoryName(category.name);
    setSearchTerm(suggestion.OpportunityName);
    setSearchSuggestions([]);
  };


  const handleInputClick = () => {
    setTimeout(() => {
      navigate('/');
    }, 100); // Adjust this timing to match your animation duration
  };

  useEffect(() => {
    AOS.init({
      duration: 500,
      easing: "ease-out-cubic",
    });
  }, []);

  const handleCategoryChange = (id, name) => {
    if (id === '') {
      // "All Opportunities" selected
      setSelectedCategory('');
      setSelectedCategoryName('');
      navigate('/search');
    } else {
      setSelectedCategory(id);
      setSelectedCategoryName(name);
      navigate(`/search/${id}`);
    }
    setSearchTerm('');
  };

  const handleBackButton = () => {
    if (location.pathname !== '/search') {
      navigate('/search');
    } else {
      navigate('/');
    }
  };

  return (
    <div>
      <Header />

      <div className="container mx-auto p-4">
        <div className="mb-4">
          <div className="max-w-4xl mx-auto mt-32">

            <form className="relative">
              <div className="flex items-center">
                <Menu as="div" className="relative inline-block text-left">
                  <div className="mr-2">
                    <Menu.Button className="flex w-full rounded-lg bg-white px-3 py-2 font-normal text-gray-700 ring-1 ring-gray-300 hover:bg-gray-50 focus:outline-none">
                      {selectedCategoryName || 'All'}
                      <ChevronDownIcon aria-hidden="true" className="ml-2 h-5 w-5 text-gray-400" />
                    </Menu.Button>
                  </div>

                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            className={`block px-4 py-2 text-sm ${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`}
                            onClick={() => handleCategoryChange('', 'All Opportunities')}
                          >
                            All Opportunities
                          </a>
                        )}
                      </Menu.Item>
                      {categories.map(category => (
                        <Menu.Item key={category.id}>
                          {({ active }) => (
                            <a
                            style={{cursor: 'pointer'}}
                              className={`block px-4 py-2 text-sm ${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`}
                              onClick={() => handleCategoryChange(category.id, category.name)}
                            >
                              {category.name}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Menu>

                <div className="relative flex-grow">


                  <div className="relative flex-grow">
                    <input
                      type="text"
                      className="w-full py-2 pl-10 pr-4 text-gray-700 bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300"
                      placeholder={selectedCategoryName ? "Search Opportunities" : "Search..."}
                      value={searchTerm}
                      onChange={(e) => {
                        setSearchTerm(e.target.value);
                        updateSearchSuggestions(e.target.value);
                      }}
                    />
                    <svg onClick={handleInputClick} style={{ marginTop: '1px' }} xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="#9CA3AF" className="bi bi-arrow-left-circle absolute left-3 top-1/2 transform -translate-y-1/2 cursor-pointer" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                    </svg>
                    
                    {searchSuggestions.length > 0 && (
                      <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-lg shadow-lg">
                        {searchSuggestions.map((suggestion, index) => (
                          <div
                            key={index}
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleSuggestionClick(suggestion)}
                          >
                            <span className="font-medium">{suggestion.OpportunityName}</span>
                            <span className="text-sm text-gray-500 ml-2">({suggestion.category})</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>

            {!selectedCategory && (
              <div>
                <div style={{marginLeft: '23px'}} className="mt-5 flex items-center">
                  <img src={logo} style={{width: '25px', marginTop: '2px'}}/>
                  <p style={{marginLeft: '5px', fontSize: '19px', fontWeight: '500'}}>All Categories</p>
                </div>
                <main className="relative  flex flex-col justify-center overflow-hidden">
                      <div className="w-full max-w-6xl mx-auto px-4 md:px-6 py-10">
                          <div className="max-w-sm mx-auto grid gap-6 lg:grid-cols-3 items-start lg:max-w-none group" data-spotlight>
                          {categories.map(category => (
                            <div key={category.id} data-aos="fade-up" data-aos-duration="400" className="relative h-full bg-slate-800 rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
                                  <div className="relative h-full bg-slate-900 p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
                                      <div className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square" aria-hidden="true">
                                          <div className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                                      </div>
                                      <div className="flex flex-col h-full items-center text-center">
                                          <div className="grow mb-5">
                                              <h2 className="text-xl text-slate-200 font-bold mb-1">{category.name}</h2>
                                              <p className="text-sm text-slate-500">Quickly filter opportunities based on {category.name} </p>
                                          </div>
                                          <Link to={`/search/${category.id}`} className="inline-flex justify-center items-center whitespace-nowrap rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150">
                                            <svg className="fill-slate-500 mr-2" xmlns="http://www.w3.org/2000/svg" width="16" height="14">
                                              <path d="M12.82 8.116A.5.5 0 0 0 12 8.5V10h-.185a3 3 0 0 1-2.258-1.025l-.4-.457-1.328 1.519.223.255A5 5 0 0 0 11.815 12H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM12.82.116A.5.5 0 0 0 12 .5V2h-.185a5 5 0 0 0-3.763 1.708L3.443 8.975A3 3 0 0 1 1.185 10H1a1 1 0 1 0 0 2h.185a5 5 0 0 0 3.763-1.708l4.609-5.267A3 3 0 0 1 11.815 4H12v1.5a.5.5 0 0 0 .82.384l3-2.5a.5.5 0 0 0 0-.768l-3-2.5ZM1 4h.185a3 3 0 0 1 2.258 1.025l.4.457 1.328-1.52-.223-.254A5 5 0 0 0 1.185 2H1a1 1 0 0 0 0 2Z" />
                                            </svg>
                                            Filter {category.name}
                                          </Link>
                                      </div>
                                  </div>

                              </div>
                            ))}  
                            <div data-aos="fade-up" data-aos-duration="400" className="relative h-full bg-slate-800 rounded-3xl p-px before:absolute before:w-80 before:h-80 before:-left-40 before:-top-40 before:bg-slate-400 before:rounded-full before:opacity-0 before:pointer-events-none before:transition-opacity before:duration-500 before:translate-x-[var(--mouse-x)] before:translate-y-[var(--mouse-y)] before:group-hover:opacity-100 before:z-10 before:blur-[100px] after:absolute after:w-96 after:h-96 after:-left-48 after:-top-48 after:bg-indigo-500 after:rounded-full after:opacity-0 after:pointer-events-none after:transition-opacity after:duration-500 after:translate-x-[var(--mouse-x)] after:translate-y-[var(--mouse-y)] after:hover:opacity-10 after:z-30 after:blur-[100px] overflow-hidden">
                                  <div className="relative h-full bg-slate-900 p-6 pb-8 rounded-[inherit] z-20 overflow-hidden">
                                      <div className="absolute bottom-0 translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none -z-10 w-1/2 aspect-square" aria-hidden="true">
                                          <div className="absolute inset-0 translate-z-0 bg-slate-800 rounded-full blur-[80px]"></div>
                                      </div>
                                      <div className="flex flex-col h-full items-center text-center">
                                          <div className="grow mb-5">
                                              <h2 className="text-xl text-slate-200 font-bold mb-1">Your Opportunity?</h2>
                                              <p className="text-sm text-slate-500">Click below to add your opportunities to sciencefair.io</p>
                                          </div>
                                          <a href="https://docs.google.com/forms/d/e/1FAIpQLSeznslqqe9NUzeTiCPv3BiAkYJm0Vd-ri5uP7QHOChtrSe-sA/viewform" className="inline-flex justify-center items-center rounded-lg bg-slate-800 hover:bg-slate-900 border border-slate-700 px-3 py-1.5 text-sm font-medium text-slate-300 focus-visible:outline-none focus-visible:ring focus-visible:ring-indigo-300 dark:focus-visible:ring-slate-600 transition-colors duration-150">
                                            Click here <span aria-hidden="true">&rarr;</span>
                                          </a>
                                      </div>
                                  </div>

                              </div>
                          </div>
                      </div>
                  </main>
              </div>
            )}
          </div>
        </div>
        <div className="max-w-4xl mx-auto mt-8">

{selectedCategory && (
  <div className="grid grid-cols-1 gap-6">
    <div>
      <div style={{marginLeft: '15px'}} className="mt-5 flex items-center">
        <img src={logo} style={{width: '25px', marginTop: '2px'}}/>
        <p style={{marginLeft: '5px', fontSize: '19px', fontWeight: '500'}}>
          Opportunities in {selectedCategoryName}
        </p>
      </div>

      <ul className="space-y-6 p-4">

        {categoryOpportunities
          .filter(opp => opp.OpportunityName === 'International Research Olympiad')
          .map(opp => (
            <li key={opp.id}>
<h3 className="mt-5 text-lg font-semibold flex items-center mb-3">
  <span className="mr-2 flex items-center">
    <span>{opp.OpportunityName}</span>
    <div className="h-4 w-px bg-black mx-2 mt-1"></div>
    <span className="font-medium flashing-text">Sponsored</span>
    <span>
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSeznslqqe9NUzeTiCPv3BiAkYJm0Vd-ri5uP7QHOChtrSe-sA/viewform">
<svg xmlns="http://www.w3.org/2000/svg" style={{marginTop: '2px'}} width="18" height="18" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"/>
</svg>
      </a>
    </span>
  </span>
</h3>

              <Link to={`/opportunity/${selectedCategory}/${opp.id}`} className="block">
                <div className="bg-white p-6 rounded-lg shadow-lg flex items-start space-x-4 border border-gray-300 hover:border-gray-400 transition-all duration-200 ease-in-out">
                  <div className="flex-shrink-0 w-32 h-32">
                    <img 
                      src={opp.ImageLink || placeholder_image} 
                      alt={opp.OpportunityName} 
                      className="w-full h-full object-cover rounded-lg shadow-sm" 
                    />
                  </div>
                  <div className="flex-1">
                    <div className="flex items-center justify-between">
                      <h2 className="text-xl font-semibold text-gray-800 mb-2">
                        {opp.OpportunityName}
                      </h2>
                      <span className="text-xl font-semibold text-gray-800">
                        {opp.Rank}
                      </span>
                    </div>
                    <div className="relative">
                      <p className="text-gray-600 mb-4">
                        {opp.OpportunityExplained.split('.').slice(0, 2).join('.')}
                      </p>
                      <div className="absolute inset-x-0 bottom-0 h-10 bg-gradient-to-t from-white via-white opacity-90" />
                    </div>
                    <p className="text-gray-500 mb-4 flex items-center space-x-2 text-sm">
                      <span><strong>Deadline:</strong> {opp.Deadline}</span>
                    </p>
                    <div className="mt-4 flex flex-wrap gap-2">
                      {opp.Keywords.map((keyword, index) => (
                        <span key={index} className="bg-[#5AA3ED] text-white px-3 py-1 rounded-full text-sm">
                          {keyword}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          ))}
        
        {categoryOpportunities
          .filter(opp => opp.OpportunityName !== 'International Research Olympiad')
          .map(opp => (
            <li key={opp.id}>
              <h3 className="text-lg font-semibold">{opp.OpportunityName}</h3>
              <Link to={`/opportunity/${selectedCategory}/${opp.id}`} className="block">
                <div className="bg-white p-6 rounded-lg shadow-lg flex items-start space-x-4">
                  <div className="flex-shrink-0 w-32 h-32">
                    <img 
                      src={opp.ImageLink || placeholder_image} 
                      alt={opp.OpportunityName} 
                      className="w-full h-full object-cover rounded-lg shadow-sm" 
                    />
                  </div>
                  <div className="flex-1">
                    <div className="flex items-center justify-between">
                      <h2 className="text-xl font-semibold text-gray-800 mb-2">
                        {opp.OpportunityName}
                      </h2>
                      <span className="text-xl font-semibold text-gray-800">
                        {opp.Rank}
                      </span>
                    </div>
                    <div className="relative">
                      <p className="text-gray-600 mb-4">
                        {opp.OpportunityExplained.split('.').slice(0, 2).join('.')}
                      </p>
                      <div className="absolute inset-x-0 bottom-0 h-10 bg-gradient-to-t from-white via-white opacity-90" />
                    </div>
                    <p className="text-gray-500 mb-4 flex items-center space-x-2 text-sm">
                      <span><strong>Deadline:</strong> {opp.Deadline}</span>
                    </p>
                    <div className="mt-4 flex flex-wrap gap-2">
                      {opp.Keywords.map((keyword, index) => (
                        <span key={index} className="bg-[#5AA3ED] text-white px-3 py-1 rounded-full text-sm">
                          {keyword}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </Link>
            </li>
          ))}
      </ul>
    </div>
  </div>
)}



        </div>
      </div>
    </div>
  );
}

export default SearchPage;






// -----------------------------


// import React, { useState, useEffect } from 'react';
// import db from '../firebase'; // Assuming your Firebase config file is named 'firebase.js'

// function OpportunityFinder() {
//   const [categories, setCategories] = useState([]);
//   const [selectedCategory, setSelectedCategory] = useState('');
//   const [opportunities, setOpportunities] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');

//   useEffect(() => {
//     const unsubscribe = db.collection('database.sciencefair.io').onSnapshot(snapshot => {
//       const categoryList = snapshot.docs.map(doc => ({
//         id: doc.id,
//         name: doc.data().name
//       }));
//       setCategories(categoryList);
//     });

//     return () => unsubscribe();
//   }, []);

//   useEffect(() => {
//     if (selectedCategory) {
//       const unsubscribe = db.collection('database.sciencefair.io').doc(selectedCategory)
//         .collection('opportunities').orderBy('rank')
//         .onSnapshot(snapshot => {
//           const opportunityList = snapshot.docs.map(doc => ({
//             id: doc.id,
//             ...doc.data()
//           }));
//           setOpportunities(opportunityList);
//         });

//       return () => unsubscribe();
//     } else {
//       setOpportunities([]);
//     }
//   }, [selectedCategory]);

//   const handleCategoryChange = (e) => {
//     setSelectedCategory(e.target.value);
//     setSearchTerm('');
//   };

//   const filteredOpportunities = opportunities.filter(opp =>
//     opp.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
//     opp.keywords.some(keyword => keyword.toLowerCase().includes(searchTerm.toLowerCase()))
//   );

//   return (
//     <div>
//       <h1>Opportunity Finder</h1>
//       <select value={selectedCategory} onChange={handleCategoryChange}>
//         <option value="">All categories</option>
//         {categories.map(category => (
//           <option key={category.id} value={category.id}>{category.name}</option>
//         ))}
//       </select>
      
//       {!selectedCategory ? (
//         <div>
//           <h2>Categories</h2>
//           <ul>
//             {categories.map(category => (
//               <li key={category.id}>
//                 <h3>{category.name}</h3>
//               </li>
//             ))}
//           </ul>
//         </div>
//       ) : (
//         <div>
//           <input
//             type="text"
//             placeholder="Search opportunities"
//             value={searchTerm}
//             onChange={(e) => setSearchTerm(e.target.value)}
//           />
//           <h2>Opportunities in {categories.find(cat => cat.id === selectedCategory)?.name}</h2>
//           <ul>
//             {filteredOpportunities.map(opp => (
//               <li key={opp.id}>
//                 <h3>{opp.title}</h3>
//                 <p>{opp.description}</p>
//                 <p>Rank: {opp.rank}</p>
//                 <p>Keywords: {opp.keywords.join(', ')}</p>
//               </li>
//             ))}
//           </ul>
//         </div>
//       )}
//     </div>
//   );
// }

// export default OpportunityFinder;



// -----------------------------






// 5AA3ED





// import React, { useState, useEffect, useRef } from 'react';
// import { collection, query, getDocs } from 'firebase/firestore';
// import db from "../firebase";

// import HomePage from './HomePage'; // Import the SearchPage component

// import AOS from "aos";
// import "aos/dist/aos.css";

// import Header from '../Header';

// import Image from '../images/logo.svg';

// import { useNavigate } from 'react-router-dom';
// import logo from '../images/logo2.webp'

// import { Menu } from '@headlessui/react'
// import { ChevronDownIcon } from '@heroicons/react/20/solid'

// function SearchPage() {

//   document.title = "Search | ScienceFair.io — Database";

//   const [searchTerm, setSearchTerm] = useState('');
//   const [searchResults, setSearchResults] = useState([]);
//   const [noSearchResults, setNoSearchResults] = useState(false);
//   const [hasSearched, setHasSearched] = useState(false);
//   const resultsRef = useRef(null);
//   const [isSearchActive, setIsSearchActive] = useState(false);

//   const [selectedOption, setSelectedOption] = useState('All');

//   const [allData, setAllData] = useState([]);


//   const handleDropdownChange = (option) => {
//     setSelectedOption(option)
//     // If you want to close the dropdown after selection, you can manage that here
//   }

//   const navigate = useNavigate();

//   const handleInputClick = () => {
//     setTimeout(() => {
//       navigate('/');
//     }, 100); // Adjust this timing to match your animation duration
//   };

//   useEffect(() => {
//     AOS.init({
//       duration: 500,
//       easing: "ease-out-cubic",
//     });
//   }, []);

//   useEffect(() => {
//     if (resultsRef.current) {
//       AOS.refresh();
//     }
//   }, [searchResults, noSearchResults]);

//   useEffect(() => {
//     AOS.init({
//       duration: 500,
//       easing: "ease-out-cubic",
//     });

//     // Fetch all data from Firestore
//     const fetchAllData = async () => {
//       const q = collection(db, 'database.sciencefair.io');
//       const querySnapshot = await getDocs(q);
//       const results = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
//       setAllData(results);
//     };

//     fetchAllData();
//   }, []);

//   const handleSearch = async (e) => {
//     e.preventDefault();

//     if (searchTerm.trim() === '') {
//       setSearchResults([]);
//       setNoSearchResults(false);
//       setHasSearched(false);
//       return;
//     }

//     setHasSearched(true);
//     const searchTermLower = searchTerm.toLowerCase();
//     const q = query(collection(db, 'database.sciencefair.io'));
    
//     const querySnapshot = await getDocs(q);
    
//     const results = [];
//     querySnapshot.forEach((doc) => {
//       const data = doc.data();
//       const keywords = data.keywords.map(kw => kw.toLowerCase());
//       if (keywords.some(keyword => keyword.includes(searchTermLower))) {
//         results.push({ id: doc.id, ...data });
//       }
//     });

//     setSearchResults(results);
//     setNoSearchResults(results.length === 0);
//   };

//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     setSearchTerm(value);
//     if (value === '') {
//       setSearchResults([]);
//       setNoSearchResults(false);
//       setHasSearched(false);
//     }
//   };

//   return (
//     <div>
//       <div>
//         <Header/>
//       </div>

//       <div className="container mx-auto p-4">

//         {!isSearchActive && (
//           <div>
//             <div className="mb-4">
//               <div className="max-w-4xl mx-auto mt-32">
//                 {/* <form className="relative" onSubmit={handleSearch}>
//                   <div className="flex items-center">
//                     <svg onClick={handleInputClick} style={{marginTop: '1px'}} xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="#9CA3AF" class="bi bi-arrow-left-circle absolute left-3" viewBox="0 0 16 16">
//                       <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
//                     </svg>

//                     <input 
//                       type="text" 
//                       className="w-full py-3 pl-11 pr-4 text-gray-700 bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300"
//                       placeholder="Search..."
//                       value={searchTerm}
//                       onChange={handleInputChange}
//                     />
//                     {searchTerm && (
//                       <button data-aos="fade-right" onClick={handleSearch} type="submit" className="absolute right-3">
//                         <svg xmlns="http://www.w3.org/2000/svg" className="h-6=5 w-5 text-[#3B82F6]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
//                         </svg>
//                       </button>
//                     )}
//                   </div>
//                 </form> */}

//                 <form className="relative" onSubmit={handleSearch}>
//                   <div className="flex items-center">
//                     <Menu as="div" className="relative inline-block text-left">
//                       <div className="mr-2">
//                         <Menu.Button className="flex w-full rounded-lg bg-white px-3 py-2 font-normal text-gray-700 ring-1 ring-gray-300 hover:bg-gray-50 focus:outline-none">
//                           {selectedOption || 'Select an option'}
//                           <ChevronDownIcon aria-hidden="true" className="ml-2 h-5 w-5 text-gray-400" />
//                         </Menu.Button>
//                       </div>

//                       <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
//                         <div className="py-1">
//                           <Menu.Item>
//                             {({ active }) => (
//                               <a
//                                 href="#"
//                                 className={`block px-4 py-2 text-sm ${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`}
//                                 onClick={() => handleDropdownChange('Option 1')}
//                               >
//                                 All Opportunities
//                               </a>
//                             )}
//                           </Menu.Item>
//                           <Menu.Item>
//                             {({ active }) => (
//                               <a
//                                 href="#"
//                                 className={`block px-4 py-2 text-sm ${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`}
//                                 onClick={() => handleDropdownChange('Option 2')}
//                               >
//                                 Science Fair
//                               </a>
//                             )}
//                           </Menu.Item>
//                           <Menu.Item>
//                             {({ active }) => (
//                               <a
//                                 href="#"
//                                 className={`block px-4 py-2 text-sm ${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`}
//                                 onClick={() => handleDropdownChange('Option 3')}
//                               >
//                                 Essay Content
//                               </a>
//                             )}
//                           </Menu.Item>
//                           <Menu.Item>
//                             {({ active }) => (
//                               <a
//                                 href="#"
//                                 className={`block px-4 py-2 text-sm ${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`}
//                                 onClick={() => handleDropdownChange('Option 3')}
//                               >
//                                 STEM Scholarships
//                               </a>
//                             )}
//                           </Menu.Item>
//                           <Menu.Item>
//                             {({ active }) => (
//                               <a
//                                 href="#"
//                                 className={`block px-4 py-2 text-sm ${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`}
//                                 onClick={() => handleDropdownChange('Option 3')}
//                               >
//                                 Research Opportunities
//                               </a>
//                             )}
//                           </Menu.Item>
//                           <Menu.Item>
//                             {({ active }) => (
//                               <a
//                                 href="#"
//                                 className={`block px-4 py-2 text-sm ${active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'}`}
//                                 onClick={() => handleDropdownChange('Option 3')}
//                               >
//                                 Olympiads
//                               </a>
//                             )}
//                           </Menu.Item>
//                         </div>
//                       </Menu.Items>
//                     </Menu>

//                     <div className="relative flex-grow">
//                       <input
//                         type="text"
//                         className="w-full py-2 pl-10 pr-4 text-gray-700 bg-white border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-300"
//                         placeholder="Search..."
//                         value={searchTerm}
//                         onChange={handleInputChange}
//                       />
//                       <svg onClick={handleInputClick} style={{marginTop: '1px'}} xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="#9CA3AF" class="bi bi-arrow-left-circle absolute left-3 top-1/2 transform -translate-y-1/2 cursor-pointer" viewBox="0 0 16 16">
//                         <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z"/>
//                       </svg>
//                     </div>

//                     {searchTerm && (
//                       <button data-aos="fade-right" onClick={handleSearch} type="submit" className="absolute right-3">
//                         <svg xmlns="http://www.w3.org/2000/svg" className="h-6=5 w-5 text-[#3B82F6]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
//                         </svg>
//                       </button>
//                     )}
//                   </div>
//                 </form>

//                 {!hasSearched &&
//                   <div>
//                     <div style={{marginLeft: '65px'}} className="mt-5 flex items-center">
//                       <img src={logo} style={{width: '25px', marginTop: '2px'}}/>
//                       <p style={{marginLeft: '5px', fontSize: '19px', fontWeight: '500'}}>Searches</p>
//                     </div>
//                     <div>
                        
//                       <div className="max-w-3xl mx-auto mt-5" ref={resultsRef}>
//                         <div className="grid grid-cols-1 gap-6">
//                           {allData.map((result) => (
//                             <div 
//                               key={result.id} 
//                               className="bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg p-6 rounded-lg shadow-lg" 
//                               data-aos="fade-down"
//                               data-aos-duration="500"
//                             >
//                               <div className="grid grid-cols-[auto,1fr] gap-6">
//                                 {result['image-link'] && (
//                                   <div className="flex items-start">
//                                     <img src={result['image-link']} alt="Opportunity" style={{width: '100px'}} className="object-cover rounded-lg" />
//                                   </div>
//                                 )}
//                                 <div>
//                                   <h2 className="text-xl font-bold mb-2">{result['opportunity-name']}</h2>
//                                   <p><strong>Deadline:</strong> {result.deadline}</p>
//                                   <p><strong>Eligibility Criteria:</strong> {result['eligibility-criteria']}</p>
//                                   <p><strong>Field:</strong> {result.field}</p>
//                                   <p><strong>Free:</strong> {result.free}</p>
//                                   <p><strong>Insights:</strong> {result.insights}</p>
//                                   <p><strong>Link:</strong> <a href={result.link} className="text-blue-500 hover:underline">{result.link}</a></p>
//                                   <p><strong>Opportunity Explained:</strong> {result['opportunity-explained']}</p>
//                                   <p><strong>Timeline:</strong> {result.timeline}</p>
//                                   <p><strong>Tips on Process:</strong> {result['tips-on-process']}</p>
//                                 </div>
//                               </div>
//                             </div>
//                           ))}
//                         </div>
//                       </div>

                      
//                     </div>
//                   </div>
//                 }
//               </div>
//             </div>
//             <div className="max-w-4xl mx-auto mt-8" ref={resultsRef}>
//               {hasSearched && noSearchResults && (
//                 <div 
//                   className="bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg p-6 rounded-lg shadow-lg" 
//                   data-aos="fade-down"
//                   data-aos-duration="500"
//                 >
//                   <p className="ml-10 mb-5 text-lg">
//                     <b>Oops, no matches found!</b>
//                     <br/>
//                     <br/>
//                     But don't worry, you have another option
//                     <div style={{marginTop: '7px'}}/>
//                     &nbsp; • Try again with different keywords.
//                   </p>
//                 </div>
//               )}
//               <div className="grid grid-cols-1 gap-6">
//                 {searchResults.map((result) => (
//                   <div 
//                     key={result.id} 
//                     className="bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg p-6 rounded-lg shadow-lg" 
//                     data-aos="fade-down"
//                     data-aos-duration="500"
//                   >
//                     <div className="grid grid-cols-[auto,1fr] gap-6">
//                       {result['image-link'] && (
//                         <div className="flex items-start">
//                           <img src={result['image-link']} alt="Opportunity" style={{width: '100px'}} className="object-cover rounded-lg" />
//                         </div>
//                       )}
//                       <div>
//                         <h2 className="text-xl font-bold mb-2">{result['opportunity-name']}</h2>
//                         <p><strong>Deadline:</strong> {result.deadline}</p>
//                         <p><strong>Eligibility Criteria:</strong> {result['eligibility-criteria']}</p>
//                         <p><strong>Field:</strong> {result.field}</p>
//                         <p><strong>Free:</strong> {result.free}</p>
//                         <p><strong>Insights:</strong> {result.insights}</p>
//                         <p><strong>Link:</strong> <a href={result.link} className="text-blue-500 hover:underline">{result.link}</a></p>
//                         <p><strong>Opportunity Explained:</strong> {result['opportunity-explained']}</p>
//                         <p><strong>Timeline:</strong> {result.timeline}</p>
//                         <p><strong>Tips on Process:</strong> {result['tips-on-process']}</p>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

// export default SearchPage;


// // 5AA3ED
