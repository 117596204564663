import React, { useEffect, useRef } from 'react';
import AnimatedHook from './hook';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const links = [
  { name: 'Merit-Based Contests', href: '/search/EssayContest(C2)' },
  { name: 'STEM Scholarships', href: '/search/STEMScholarship(C3)' },
  { name: 'Research Opportunities', href: '/search/ResearchOpportunities(C4)' },
  { name: 'Prestigious Olympiads', href: '/search/Olympiads(C5)' },
]

const AnimatedSection = ({ image2, image4 }) => {
  const sectionRef = useRef(null);
  const lowerRef = useRef(null);
  const titleRef = useRef(null);
  const paragraphRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(() => {
    const section = sectionRef.current;
    const title = titleRef.current;
    const paragraph = paragraphRef.current;
    const lower = lowerRef.current;
    const image = imageRef.current;

    gsap.set([title, paragraph, lower, image], { autoAlpha: 0, y: 50 });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: 'top 80%',
        end: 'bottom 20%',
        toggleActions: 'play none none reverse',
      },
    });

    tl.to(title, { duration: 1, autoAlpha: 1, y: 0, ease: 'power3.out' })
      .to(paragraph, { duration: 1, autoAlpha: 1, y: 0, ease: 'power3.out' }, '-=0.5')
      .to(lower, { duration: 1, autoAlpha: 1, y: 0, ease: 'power3.out' }, '-=0.5')
      .to(image, { duration: 1, autoAlpha: 1, y: 0, ease: 'power3.out' }, '-=0.5');

    // Animated background for "Curated" text
    gsap.to('.animated-text', {
      backgroundPosition: '100% 100%',
      duration: 5,
      repeat: -1,
      yoyo: true,
      ease: 'power1.inOut',
    });

    return () => {
      tl.kill();
    };
  }, []);



  return (
    <div ref={sectionRef} className="py-16 overflow-hidden">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="flex justify-center flex-col">
          <h1 ref={titleRef} className="text-4xl md:text-5xl font-extrabold mb-5 transition-all duration-300 ease-in-out">
            Exclusively{' '}
            <span
              className="animated-text inline-block"
              style={{
                color: 'transparent',
                backgroundImage: `url(${image2})`,
                WebkitBackgroundClip: 'text',
                backgroundClip: 'text',
                backgroundSize: '200% 200%',
              }}
            >
              Curated
            </span>{' '}
            for STEM Excellence
          </h1>
          <p
            ref={paragraphRef}
            className="text-lg md:text-xl leading-8 text-gray-900 mb-10 transition-all duration-300 ease-in-out hover:text-gray-600"
          >
            At our core, we believe in quality over quantity. Unlike other platforms, we meticulously
            vetted <b>60</b> STEM competitions and scholarships that meet the highest standards of
            educational and professional excellence. Our commitment is to provide students and
            educators with access to only the most prestigious and impactful opportunities in STEM.
          </p>
          <div ref={lowerRef} className="grid grid-cols-1 gap-x-8 gap-y-6 text-base font-semibold leading-7 text-black sm:grid-cols-2 md:flex lg:gap-x-10">
            {links.map((link) => (
              <a key={link.name} href={link.href}>
                {link.name} <span aria-hidden="true">&rarr;</span>
              </a>
            ))}
          </div>
        </div>
        
        <div className="flex justify-center items-center">
          <img
            ref={imageRef}
            className="w-3/4 mb-8  md:w-full max-w-md transform transition-all duration-300 ease-in-out hover:scale-110 hover:rotate-3"
            src={image4}
            alt="STEM Excellence"
          />
        </div>
      </div>

      <AnimatedHook />
    </div>
  );
};

export default AnimatedSection;