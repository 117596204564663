import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import SearchPage from './components/SearchPage';
import RishabList from './components/RishabList';
import DatabaseAdd from './components/databaseAdd';
import ExpandedPage from './components/ExpandedPage';
// import CSVUploader from './CSVUploader';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/Rishab-list" element={<RishabList />} />
        // <Route path="/databaseAdd" element={<DatabaseAdd />} />
        <Route path="/opportunity/:categoryId/:oppId" element={<ExpandedPage />} />
        {/* <Route path="/upload" element={<CSVUploader />} /> */}
        <Route path="/search/:category" element={<SearchPage />} />
      </Routes>
    </Router>
  );
}

export default App;
